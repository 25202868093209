import React from 'react';
import PropTypes from 'prop-types';

import { useFormState } from '../../../context/FormContext';
import { useAuth } from '../../../context/AuthContext';
import PreviousComment from './PreviousComment';
import Comment from './Comment';
import useStyles from '../../../styles/evals/commentSectionStyles';
import { STATUS, FORM_TYPE } from '../../../helpers/constants';
import { formIsReadOnly, idMatch } from '../../../helpers/helpers';
import {
  userIsAdmin,
  userIsPrimary,
  userIsManager
} from '../../../helpers/roleHelpers';

/* Component containing TextField for new comments and previously added comments */
const CommentSection = React.memo(
  ({
    errorOccurred,
    updateCommentValues,
    updatedComments,
    sectionId,
    sectionTitle,
    setCommentsToBeDeleted
  }) => {
    const {
      state: {
        auditData: {
          evaluationForEmail,
          evaluationForEmployeeId,
          assignedToEmail,
          assignedToEmployeeId
        },
        formConfig: { formTypeId, status, view },
        formComments,
        printVersion
      }
    } = useFormState();

    const {
      userData: { email: currentUserEmail, role, employeeId }
    } = useAuth();

    const classes = useStyles();

    // Conditionals
    const formIsOpen = () => status === STATUS.OPEN.NAME;
    const formIsClosed = () => status === STATUS.ARCHIVED.NAME;
    const formIsPublished = () => status === STATUS.PUBLISHED.NAME;
    const formIsLocked = () =>
      formIsClosed() ||
      formIsReadOnly(
        currentUserEmail,
        assignedToEmail,
        employeeId,
        assignedToEmployeeId,
        view
      );
    const formTypeIsIntern = () => formTypeId === FORM_TYPE.INTERN.ID;
    const userIsEvaluated =
      idMatch(currentUserEmail, evaluationForEmail) ||
      idMatch(employeeId, evaluationForEmployeeId);

    const previousCommentsClassName = comment => {
      if (formIsLocked()) return null;

      // previous comments cannot be edited unless user created them
      if (
        (!idMatch(comment.createdByEmail, currentUserEmail) &&
          !idMatch(comment.createdByEmployeeId, employeeId)) ||
        formIsPublished()
      ) {
        return 'unEditable';
      }
      return 'editable';
    };

    const commentsClassName = () => {
      if (formIsLocked() || (formIsPublished() && !printVersion)) {
        return 'closedStatus';
      }
      if (userCommentExists) {
        return 'disabled';
      }
      if (userIsEvaluated && formTypeIsIntern()) {
        return 'hiddenComment';
      }
      return 'enterComment';
    };

    const getCommentValue = id => {
      let comments;
      if (updatedComments.length) {
        if (!id) {
          comments = updatedComments.filter(
            cmt => cmt.id === undefined && cmt.sectionId === sectionId
          );
        } else {
          comments = updatedComments.filter(
            cmt => cmt.id === id && cmt.sectionId === sectionId
          );
        }
        if (comments.length) {
          const { comment } = comments[0];
          return comment;
        }
      }

      return undefined;
    };

    const defaultPrevComment = comment => {
      if (errorOccurred) {
        return getCommentValue(comment.id) || comment.comment;
      }
      if (!updatedComments.length || formIsPublished()) {
        return comment.comment;
      }
      return undefined;
    };

    const defaultCommentText = () => {
      if (updatedComments.length && !formIsPublished()) {
        return getCommentValue();
      }
      return '';
    };

    const checkBoxPresent = comment => {
      if (formIsOpen()) {
        // users see deletion checkbox for comments they created
        if (idMatch(comment.createdByEmail, currentUserEmail)) return true;
        if (idMatch(comment.createdByEmployeeId, employeeId)) return true;
        // admins can delete comments on the evaluations of others
        if (userIsAdmin(role) && !userIsEvaluated) return true;
      }
      return false;
    };

    const prevCommentDisabled = comment => {
      if (formIsLocked() || formIsPublished()) return true;
      // previous comments are disabled unless user created them
      return (
        !idMatch(comment.createdByEmployeeId, employeeId) &&
        !idMatch(comment.createdByEmail, currentUserEmail)
      );
    };

    const renderPrevComments = comments =>
      comments.map(comment => {
        const commentData = {
          commentId: comment.id,
          commentCreator: comment.createdBy,
          createdOn: comment.createdOn
        };

        return (
          <PreviousComment
            key={comment.id || 0}
            checkBox={checkBoxPresent(comment)}
            classname={previousCommentsClassName(comment)}
            comment={comment.comment || defaultPrevComment(comment)}
            commentData={commentData}
            disabled={prevCommentDisabled(comment)}
            sectionId={sectionId}
            setCommentsToBeDeleted={setCommentsToBeDeleted}
            updateCommentValues={updateCommentValues}
            updatedComments={updatedComments}
          />
        );
      });

    const employeeComments = formComments.filter(
      comment =>
        (idMatch(comment.createdByEmail, evaluationForEmail) ||
          idMatch(comment.createdByEmployeeId, evaluationForEmployeeId)) &&
        comment.sectionId === sectionId
    );

    const reviewerComments = formComments.filter(
      comment =>
        !idMatch(comment.createdByEmail, evaluationForEmail) &&
        !idMatch(comment.createdByEmployeeId, evaluationForEmployeeId) &&
        comment.sectionId === sectionId
    );

    const userCommentExists = formComments.some(
      comment =>
        (idMatch(comment.createdByEmployeeId, employeeId) ||
          idMatch(comment.createdByEmail, currentUserEmail)) &&
        comment.sectionId === sectionId
    );

    const textLabelDisplay = () => {
      if (formIsPublished()) {
        return 'Comments cannot be added when published';
      }

      if (userCommentExists) {
        return 'Edit your previous comment below';
      }

      return 'Add comments here';
    };

    const commentFieldIsDisabled = () => {
      if (userCommentExists || formIsPublished()) return true;

      // interns cannot add comments to their own evaluations
      if (userIsEvaluated && formTypeIsIntern()) return true;

      // executive comments can only be added by executives and above.
      // executives cannot add executive comments to their own evaluations
      if (sectionTitle === 'Executive Comments') {
        return userIsEvaluated || userIsPrimary(role) || userIsManager(role);
      }
      return false;
    };

    return (
      <div>
        <Comment
          classname={commentsClassName()}
          defaultVal={defaultCommentText()}
          disableCommentField={commentFieldIsDisabled()}
          sectionId={sectionId}
          textLabel={textLabelDisplay().toUpperCase()}
          updateCommentValues={updateCommentValues}
        />
        {employeeComments.length !== 0 && !formTypeIsIntern() && (
          <div>
            <p className={classes.commentHeader}>Employee comments:</p>
            {renderPrevComments(employeeComments)}
          </div>
        )}
        {reviewerComments.length !== 0 && (
          <div>
            <p
              className={
                formTypeIsIntern()
                  ? classes.commentHeaderSmaller
                  : classes.commentHeader
              }
            >
              Reviewer comments:
            </p>
            {renderPrevComments(reviewerComments)}
          </div>
        )}
      </div>
    );
  }
);

CommentSection.defaultProps = {
  sectionTitle: null
};

CommentSection.propTypes = {
  errorOccurred: PropTypes.bool.isRequired,
  sectionId: PropTypes.number.isRequired,
  sectionTitle: PropTypes.string,
  setCommentsToBeDeleted: PropTypes.func.isRequired,
  updateCommentValues: PropTypes.func.isRequired,
  updatedComments: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default CommentSection;
